import {Categories} from "../components"
import { Helmet } from "react-helmet";
import CookieConsent from "react-cookie-consent";

const CategoriesPage = () => (
<>
    <Helmet>
    <title>
      Categorii | DiaDrive
    </title>
    <meta name="description" content="Aici puteti vizualiza toate categoriile noastră !" />
  </Helmet>

    <section className="p-6">
    <Categories/>
    </section>
    <CookieConsent
    buttonText="Am înțeles."
    style={{ background: "rgba(254, 72, 117, 1)",fontSize: "20px" }}
        >Acest site utilizează cookie-uri pentru a îmbunătăți experiența utilizatorului.
        </CookieConsent>
</>
)

export default CategoriesPage;