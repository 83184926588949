import { Helmet } from "react-helmet";
import { Gallery } from "../components";
import CookieConsent from "react-cookie-consent";

const GalleryPage = () => (
  <>
  <Helmet>
    <title>
      Galerie Foto | DiaDrive
    </title>
    <meta name="description" content="Aici puteti vizualiza toată flota noastră auto!" />
  </Helmet>
    <section className="">
 
    <h1 className='border-b-2 border-neonPink text-center ss:text-[30px] text-neonPink font-bold w-fit mx-auto my-10'>Flota Auto -<span className="text-gradient"> Dia Drive</span></h1>
    <Gallery className='' />
  
  
</section>
<CookieConsent
    buttonText="Am înțeles."
    style={{ background: "rgba(254, 72, 117, 1)",fontSize: "20px" }}
        >Acest site utilizează cookie-uri pentru a îmbunătăți experiența utilizatorului.
        </CookieConsent>
</>
)

export default GalleryPage;