import { Helmet } from "react-helmet";
import { Hero, Stats } from "../components";
import CookieConsent from "react-cookie-consent";


const Homepage = () => (
    <>
    <Helmet>
        <title>
            Acasă | DiaDrive
        </title>
        <meta name="description" content="Scoala de soferi DiaDrive | Categ. B B1 B special BE C CE D DE" />
    </Helmet>
    <section className="p-4">
        <Hero />
        <Stats />
    </section>
    <CookieConsent
    buttonText="Am înțeles."
    style={{ background: "rgba(254, 72, 117, 1)",fontSize: "20px" }}
        >Acest site utilizează cookie-uri pentru a îmbunătăți experiența utilizatorului.
        </CookieConsent>
    </>
);

export default Homepage;