import { Contact, Faq, Map } from "../components";
import { Helmet } from "react-helmet";
import CookieConsent from "react-cookie-consent";

const ContactPage = () => (
  <>
    <Helmet>
      <title>Contactează-ne | DiaDrive</title>
      <meta
        name="description"
        content="Inscrie-te acum!
Acesta este primul pas pentru obținerea permisului de conducere! Ce mai aștepți?"
      />
    </Helmet>
    <section className="">
      <div className="z-10 p-4 rounded-2xl pb-6">
        <Contact />
        <Faq />
        <Map />
      </div>
    </section>
    <CookieConsent
    buttonText="Am înțeles."
    style={{ background: "rgba(254, 72, 117, 1)",fontSize: "20px" }}
        >Acest site utilizează cookie-uri pentru a îmbunătăți experiența utilizatorului.
        </CookieConsent>
  </>
);

export default ContactPage;
